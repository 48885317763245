import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Logo } from "../../static/logo";
import {ButtonOrange} from "../../styles/Buttons";
import { T } from "../../styles/Theme";
import { Menu } from "@styled-icons/heroicons-solid/Menu";
import NavModal from "./NavModal";
import { menuData } from "../../static/MenuData";
import { Link } from "gatsby";

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [scrollPosition, setScrollPosition] = useState(0);
  const [goingUp, setGoingUp] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (scrollPosition < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (scrollPosition > currentScrollY && !goingUp) {
        setGoingUp(true);
      }
      setScrollPosition(currentScrollY);
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp, scrollPosition]);

  return (
    <>
      <Nav scrollposition={scrollPosition}>
        <LogoStyled scrollposition={scrollPosition} />

        <ul>
          {menuData.map((el, i) => {
            return el.title !== "Acceso Clientes" ? (
              <Link to={el.link} key={i}>
                <li>{el.title.toUpperCase()}</li>
              </Link>
            ) : (
              <li key={i}>
                <ButtonOrange href={el.link} className="button-orange"><span>{el.title.toUpperCase()}</span></ButtonOrange>
              </li>
            );
          })}
        </ul>

        <MenuStyled onClick={handleOpen} />
      </Nav>
      <NavModal open={open} handleClose={handleClose} />
    </>
  );
};

export default Navbar;

const LogoStyled = styled(Logo)`
  transition: 0.3s;
  width: ${(props) => (props.scrollposition < 150 ? "184px" : "120px")};
`;

const Nav = styled.nav`
  z-index: 10000;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 0 30px;
  height: ${(props) => (props.scrollposition < 150 ? "130px" : "90px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  font-size: 14px;
  transition: 0.3s;
  
  .button-orange {
    padding: 0 20px;
   
    & > span {
      color: white;
    }
  }

  ul {
    display: none;
    list-style: none;

    li {
      padding: 5px 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }

      &:last-child {
        padding-top: 5px;
        padding-right: 0;
        font-weight: 600;
      }
      &:first-child {
        padding: 5px 20px 5px 0;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
    }

    a:active {
      color: inherit;
    }

    a:visited {
      color: inherit;
    }
  }

  ul {
    li:last-child {
      display: none;
    }
  }

  @media (min-width: 680px) {
    ul {
      display: flex;
      align-items: center;
    }
  }

  @media (min-width: 880px) {
    ul {
      li:last-child {
        display: flex;
      }
    }
  }
`;

const MenuStyled = styled(Menu)`
  width: 40px;
  color: ${T.orange};
  cursor: pointer;

  @media (min-width: 880px) {
    display: none;
  }
`;
