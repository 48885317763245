import React from "react";
import { Link } from "gatsby"

import styled from "styled-components";


import { Logo2 } from "../../static/logo";
import { T } from "../../styles/Theme";
import {
    ChileLogo,
    EnvelopeIcon,
    FacebookIcon,
    InstagramIcon,
    LinkedinIcon,
    PhoneIcon,
    WhatsappIcon,
    WifiIcon
} from "../../static/footer";
import {menuData} from "../../static/MenuData";

const Footer = () => {
  return (
    <FooterStyled>
        <a className="anchor" id="footer"></a>
      <div className="footer--container">
          <div className="footer--socialmedia">
              <a href="https://www.linkedin.com/company/inglobo-chile/" target = "_blank" rel="noreferrer"><LinkedinIcon/></a>
              <a href="https://www.facebook.com/inglobochile.inglobo" target = "_blank" rel="noreferrer"><FacebookIcon/></a>
              <a href="https://www.instagram.com/inglobochile/" target = "_blank" rel="noreferrer"><InstagramIcon/></a>
          </div>

          <div className="footer--info">
              <ul>
                  <li>
                      <EnvelopeIcon/>
                      comunicaciones@inglobochile.com
                  </li>
                  <li>
                      <PhoneIcon/>
                      (+56 72) 221 0957
                  </li>
                  <li>
                      <WhatsappIcon/>
                      (+569) 9821 7534
                  </li>
                  <li>
                      <WifiIcon/>
                      inglobochile.cl
                      inglobochile.com
                  </li>
              </ul>
          </div>

          <div className="footer--logo">
              <Logo2 />
          </div>

          <div className="footer--menu">
              <ul>
                  {
                      menuData.map((item) => (
                          <Link to={item.link} key={item.title}>
                              <li>
                                  {item.title}
                              </li>
                          </Link>
                      ))
                  }
              </ul>
          </div>

          <div className="footer--logo_chile">
              <ChileLogo/>
          </div>
      </div>

        <div className="block">
            <p>
              WEB DESARROLLADA POR <span>PRAGMALAB</span>
            </p>
            <p>
              © Inglobo Chile 2022 - Todos los derechos reservados.
            </p>
        </div>
    </FooterStyled>
  );
};

export default Footer;

const FooterStyled = styled.footer`
  background-color: ${T.gray};
  color: white;

  .block {
    margin-top: auto;
    width: 100%;
    padding: 15px 0;
    border-top: 1px solid #8aa292;
    text-align: center;
  }

  p {
    font-family: "Work Sans", sans-serif;
    font-size: 12px;
    color: #8aa292;

    span {
      color: ${T.orange};
    }
  }
  
  .footer--container {
    .footer--socialmedia {
      display: flex;
      align-items: center;
      justify-content: center;
      
      svg {
        margin: 25px;
        cursor: pointer;
        
        &:hover {
          opacity: 0.8;
        }
      }
    }
    
    .footer--logo, .footer--logo_chile {
      display: flex;
      justify-content: center;
    }
    
    .footer--info {
      margin: 40px auto 30px auto;
      
      li {
        margin: 2px 0;
      }
      svg {
        //transform: scale(1.4);
        padding-right: 15px;
      }
    }
    
    ul {
      list-style: none;
      font-size: 14px;
      padding: 0;
      margin: 15px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      li {
        display: flex;
        align-items: center;
      }
      
      a {
        color: white;
        text-decoration: none;
        
        &:hover {
          opacity: 0.8;
        }
        &:visited, &:active {
          color: white;
        }
      }
    }
    
    @media (min-width: 720px) {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      place-items: center;

      ul {
        width: inherit;
        display: block;
      }
      
      .footer--socialmedia {
        flex-direction: column;
        
        svg {
          margin: 4px;  
        }
      }
      
      .footer--logo {
        place-self: center;
      }
    }
  }
`;
