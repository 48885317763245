import styled from "styled-components";
import {T} from "./Theme"

const Button = styled.a`
  border-radius: ${T.borderRadius};
  display: flex;
  justify-content: center;
  align-items: center;
  padding:5px;
  text-align: center;
  
`;


export const ButtonLine = styled(Button)`
  border: 1px solid ${T.orange};
  height: 35px;
  min-width: 160px;
  cursor: pointer;
  
  &:active {
    filter: brightness(0.7)
  }
`;

export const ButtonOrange = styled(Button)`
  color: white;
  height: 50px;
  background-color: ${T.orange};
  max-width: ${(props) => props.w};
  cursor: pointer;
  text-underline: none;
  text-decoration: none;

  &:hover {
    filter: brightness(0.85);
  }
  
  &:active {
    filter: brightness(0.7)
  }
`;