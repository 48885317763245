import { createGlobalStyle } from "styled-components";
import { T } from "./Theme";

const GlobalStyle = createGlobalStyle`
  * {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 0;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${T.orange};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: ${T.gray};
    }
  }
  
  html {
    scroll-behavior: smooth;    
  }
  
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    color: ${T.gray}
  }

  .title-section {
    text-align: center;
    font-size: 3em;
    margin: 0 0 15px 0;
  }

  .title-description {
    margin-bottom: 50px;
    padding: 0 20px;
    text-align: center;
    max-width: ${T.maxWidth} !important;
  }
`;

export default GlobalStyle;
