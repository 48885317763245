import React from "react";
import styled from "styled-components";
import { T } from "../../styles/Theme";
import { AnimatePresence, motion } from "framer-motion";
import { menuData } from "../../static/MenuData";

import { Link } from "gatsby";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

function NavModal({ open, handleClose }) {
  return (
    <>
      <AnimatePresence>
        {open && (
          <ModalContainer
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            variants={variants}
            className={"noselect"}
          >
            <i className="fas fa-times" onClick={handleClose}></i>
            <PinkBar />
            <ListContainer>
              {menuData.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item.link} onClick={handleClose}>
                      {item.title.toUpperCase()}
                    </Link>
                  </li>
                );
              })}
            </ListContainer>
          </ModalContainer>
        )}
      </AnimatePresence>
    </>
  );
}

export default NavModal;

const ModalContainer = styled(motion.div)`
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 15000;
  display: grid;
  place-items: center;

  i {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    cursor: pointer;
  }
`;

const ListContainer = styled.ul`
  text-align: right;
  list-style: none;
  font-size: 1.6rem;
  margin-right: -70px;
  color: ${T.gray};

  & > li {
    margin: 30px 0;
    cursor: pointer;
  }

  & > li > a {
    text-decoration: none;
    font-weight: 600;
    color: ${T.gray};

    &:visited {
      color: ${T.gray};
    }

    &:hover {
      color: ${T.orange};
    }

    &:active {
      color: ${T.orange};
    }
  }
`;

const PinkBar = styled.div`
  left: 0;
  position: absolute;
  background-color: ${T.orange};
  width: 15px;
  height: 100%;
`;
